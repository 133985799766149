import type { AppProps } from 'next/app';

import 'styles/globals.scss';

function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: Record<string, string> }): JSX.Element {
  return <Component {...pageProps} err={err} />;
}

export default App;
